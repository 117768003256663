// function addLocationButton() {
//   console.log("zczczczczcz");
//
//   // google.maps.event.addListener(map, 'dragend', function() {
//   //   $('#you_location_img').css('background-position', '0px 0px');
//   // });
//
//   var firstChild = document.getElementById('myLocationButtonId');
//   firstChild.addEventListener('click', function () {
//     var imgX = '0';
//     var animationInterval = setInterval(function () {
//       if (imgX == '-18') imgX = '0';
//       else imgX = '-18';
//       $('#you_location_img').css('background-position', imgX + 'px 0px');
//     }, 500);
//     findMyGeolocation(animationInterval);
//   });
//
//   var myLocationMapElement = document.getElementById('myLocationMapElementId');
//   myLocationMapElement.index = 1;
//   map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(myLocationMapElement);
// };
//
// addLocationButton();
